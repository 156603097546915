import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NavigateNext } from "@mui/icons-material";
import { FHInlineSvg } from "../FHInlineSvg";
import { useIsMobile } from "../../../hooks/hooks";

const useStyles = makeStyles((theme) => ({
  root: ({ variant }: any) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    "& .MuiTypography-root": {
      color:
        variant === "light"
          ? theme.palette.background.paper
          : theme.palette.text.primary,
    },
  }),
  icon: {
    marginRight: theme.spacing(1),
  },
  breadcrumbWithIcon: {
    display: "flex",
  },
  separator: ({ variant }: any) => ({
    color:
      variant === "light"
        ? theme.palette.background.paper
        : theme.palette.text.primary,
  }),
}));

export type FHBreadcrumbStep = {
  label?: string;
  link?: string;
  iconUrl?: string;
  onClick?: () => void;
};

export type FHBreadcrumbProps = {
  breadcrumbs: FHBreadcrumbStep[];
  separator?: JSX.Element;
  theme?: "light" | "dark";
  maxItemsOnMobile?: number;
  expChangeHomeIcon?: boolean;
};

export const FHBreadcrumb = ({
  breadcrumbs,
  separator,
  theme = "light",
  maxItemsOnMobile = 2,
  expChangeHomeIcon = false,
}: FHBreadcrumbProps) => {
  const classes = useStyles({ variant: theme });
  if (expChangeHomeIcon) {
    breadcrumbs[0].iconUrl = undefined;
    breadcrumbs[0].label = "Home";
  }

  return (
    <Breadcrumbs
      separator={separator || <NavigateNext className={classes.separator} />}
      aria-label="breadcrumb"
      className={classes.root}
      maxItems={useIsMobile() ? maxItemsOnMobile : undefined}
      data-testid="breadcrumbs"
    >
      {breadcrumbs.map((breadcrumb, index) => (
        <div key={index} data-testid="breadcrumb">
          {!!breadcrumb.link ? (
            <Link
              // variant="body2"
              href={breadcrumb.link}
              aria-label={breadcrumbs.length - 1 === index ? "page" : undefined}
              onClick={breadcrumb.onClick || undefined}
              className={
                breadcrumb.iconUrl ? classes.breadcrumbWithIcon : undefined
              }
              underline="none"
            >
              {breadcrumb.iconUrl && (
                <FHInlineSvg
                  src={breadcrumb.iconUrl}
                  size={"20px"}
                  className={breadcrumb.label ? classes.icon : undefined}
                />
              )}
              {breadcrumb.label}
            </Link>
          ) : (
            <Typography
              variant="body2"
              className={
                breadcrumb.iconUrl ? classes.breadcrumbWithIcon : undefined
              }
            >
              {breadcrumb.iconUrl && (
                <FHInlineSvg
                  src={breadcrumb.iconUrl}
                  size={"20px"}
                  className={breadcrumb.label ? classes.icon : undefined}
                />
              )}
              {breadcrumb.label}
            </Typography>
          )}
        </div>
      ))}
    </Breadcrumbs>
  );
};
